async function respond (res) {
  const { status } = res
  if (res.ok) {
    const payload = await res.text()
    if (payload.length) {
      return JSON.parse(payload)
    }
    return null
  }
  throw new FetchException({ code: status })
}

function FetchException ({ code, message }) {
  this.code = code
  this.message = message
  this.toString = () => (this.message)
}

export { respond, FetchException }
