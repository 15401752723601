import { h, Component, Fragment, createRef } from 'preact'
import upcs from '../modules/upcs'
import { respond } from '../modules/api.service'
import Loading from './Loading'
import Header from './Header'
import Footer from './Footer'

export default class App extends Component {
  state = {
    status: 'INIT', // LOADING || COMPLETE
    serialNumber: '',
    upc: '',
    details: {},
    note: ''
  }

  upcRef = createRef()

  componentDidUpdate (_, prevState) {
    if ((!prevState.serialNumber && this.state.serialNumber && this.upcRef) || this.state.note === 'INVALID_UPC') {
      this.upcRef.current.focus()
    }
  }

  handleSubmit = async (ev) => {
    ev.preventDefault()
    const { serialNumber, upc } = this.state
    this.setState({ status: 'LOADING' })
    try {
      const res = await fetch(`${process.env.STREAMLABS_FACTORY_API_HOST}/v1/device`, {
        method: 'PUT',
        body: JSON.stringify({ serialNumber, upc }),
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.STREAMLABS_FACTORY_API_KEY
        }
      })

      await respond(res)
      this.setState({ status: 'COMPLETE', note: 'UNIT_SUBMIT_SUCCESS' })
    } catch (err) {
      let note = ''
      switch (err.code) {
        case 404:
          note = 'INVALID_SERIAL_NUMBER'
          break
        default:
          note = ''
      }
      this.setState({ status: 'COMPLETE', note })
    }
  }

  handleReset = (ev) => {
    ev.preventDefault()
    window.location.reload(true)
  }

  handleSerialNumberInput = (ev) => {
    const { target } = ev
    const { value: serialNumber } = target
    target.parentElement.classList.remove('error')

    if (target.value.match(/^([\d]{0,9})([A-Z]{2,3}[\d])([\w\d-]*)?$/gi)) {
      this.setState({ serialNumber, note: '' })
    } else {
      target.parentElement.classList.add('error')
      this.setState({ serialNumber: '', note: 'INVALID_SERIAL_NUMBER' })
    }
  }

  handleUPCInput = (ev) => {
    const { target } = ev
    target.parentElement.classList.remove('error')
    const details = (Object.keys(upcs).indexOf(target.value) > -1) ? upcs[target.value] : {}

    if (Object.keys(details).length > 0) {
      this.setState({
        upc: target.value,
        details,
        note: ''
      })
    } else {
      this.setState({ upc: '', details: {}, note: 'INVALID_UPC' })
      this.upcRef.current.focus()
      target.parentElement.classList.add('error')
    }
  }

  render (_, { details, serialNumber, upc, status }) {
    return (
      <Fragment>
        <Header />
        <NoticeBoard {...this.state} />
        <form id='root' method='POST' action='/'>
          <div class='form-control'>
            <label for='serial-number'>serial number: </label>
            <input id='serial-number' name='serial-number' type='text' value={serialNumber} onChange={this.handleSerialNumberInput} autofocus readOnly={(status !== 'INIT')} />
          </div>
          <div class='form-control'>
            <label for='upc'>upc code: </label>
            <input id='upc' name='upc' type='text' value={upc} onChange={this.handleUPCInput} ref={this.upcRef} autofocus readOnly={(status !== 'INIT')} />
          </div>
          {(Object.keys(details).length > 0) &&
            <dl>
              <div className='prop'>
                <dt>part number:</dt>
                <dd>{details.partNumber}</dd>
              </div>
              <div className='prop'>
                <dt>unit:</dt>
                <dd>{details.desc}</dd>
              </div>
              <div className='prop'>
                <dt>plan:</dt>
                <dd>{details.plan}</dd>
              </div>
            </dl>}
          {(status === 'LOADING') && <Loading />}
          {(Object.keys(details).length > 0) &&
            <div class='actions'>
              {(status === 'INIT') && <button id='submit' type='button' onClick={this.handleSubmit}> Submit </button>}
              {(status === 'INIT') && <button id='reset' type='button' onClick={this.handleReset}> Reset </button>}
              {(status === 'COMPLETE') && <button id='next' type='button' onClick={this.handleReset}> Next </button>}
            </div>}
        </form>
        <Footer />
      </Fragment>
    )
  }
}

const NoticeBoard = ({ note }) => {
  switch (note) {
    case 'INVALID_UPC':
      return <p id='notice-board' class='error'> Invalid UPC code</p>
    case 'INVALID_SERIAL_NUMBER':
      return <p id='notice-board' class='error'> Invalid serial number</p>
    case 'UNIT_SUBMIT_SUCCESS':
      return <p id='notice-board' class='success'>Unit submitted successfully</p>
    case 'UNIT_NETWORK_FAIL':
      return <p id='notice-board' class='error'>Network error please contact support</p>
    case 'UNIT_SUBMIT_FAIL':
      return <p id='notice-board' class='fail'>Recalled Unit, Do not ship</p>
    default:
      return null
  }
}
