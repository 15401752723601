import { h } from 'preact'
import Logo from './Logo'

const Header = () => (
  <header>
    <Logo viewBox='10 0 60 145' />
    <h1>StreamPlus<sup>&trade;</sup> Scanner</h1>
  </header>
)

export default Header