import { h } from 'preact'
import { version } from '../../package.json'

const Footer = () => (
  <footer>
    <p>
      StreamLabs<sup>&reg;</sup> &nbsp; Factory v{version}. For support, please contact support@streamlabswater.com or dial Rachel Sanford at extension 4041
    </p>
  </footer>
)

export default Footer
