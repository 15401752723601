export default {
  697285051133: {
    desc: 'SL CTRL 3/4" SB US WHOLESALE',
    plan: 'Premium',
    channel: 'Wholesale',
    partNumber: 'UFCV-01013001'
  },
  697285051140: {
    desc: 'SL CTRL 1" SB US WHOLESALE',
    plan: 'Premium',
    channel: 'Wholesale',
    partNumber: 'UFCV-01013002'
  },
  697285051157: {
    desc: 'SL CTRL 1 1/4" SB US WHOLESALE',
    plan: 'Premium',
    channel: 'Wholesale',
    partNumber: 'UFCV-01013003'
  },
  697285051164: {
    desc: 'SL CTRL 3/4" FNPT US WHOLESALE',
    plan: 'Premium',
    channel: 'Wholesale',
    partNumber: 'UFCV-01013011'
  },
  697285051171: {
    desc: 'SL CTRL 1" FNPT US WHOLESALE',
    plan: 'Premium',
    channel: 'Wholesale',
    partNumber: 'UFCV-01013012'
  },
  697285051188: {
    desc: 'SL CTRL 1 1/4" FNPT US WHOLESALE',
    plan: 'Premium',
    channel: 'Wholesale',
    partNumber: 'UFCV-01013013'
  },
  697285050495: {
    desc: 'SL CTRL 3/4" SB US',
    plan: 'Enhanced Monthly',
    channel: 'Online Retail',
    partNumber: 'UFCV-01011001'
  },
  697285050501: {
    desc: 'SL CTRL 1" SB US',
    plan: 'Enhanced Monthly',
    channel: 'Online Retail',
    partNumber: 'UFCV-01011002'
  },
  697285050518: {
    desc: 'SL CTRL 1 1/4" SB US',
    plan: 'Enhanced Monthly',
    channel: 'Online Retail',
    partNumber: 'UFCV-01011003'
  },
  697285050525: {
    desc: 'SL CTRL 3/4" FNPT US',
    plan: 'Enhanced Monthly',
    channel: 'Online Retail',
    partNumber: 'UFCV-01011011'
  },
  697285050532: {
    desc: 'SL CTRL 1" FNPT US',
    plan: 'Enhanced Monthly',
    channel: 'Online Retail',
    partNumber: 'UFCV-01011012'
  },
  697285050549: {
    desc: 'SL CTRL 1 1/4" FNPT US',
    plan: 'Enhanced Monthly',
    channel: 'Online Retail',
    partNumber: 'UFCV-01011013'
  },
  697285055025: {
    desc: 'SL CTRL 3/4" SB US RETAIL',
    plan: 'Enhanced Monthly',
    channel: 'In Store Retail',
    partNumber: 'UFCV-01012001'
  },
  697285053526: {
    desc: 'SL CTRL 1" SWT US WHOLESALE',
    plan: 'Premium',
    channel: 'Wholesale',
    partNumber: 'UFCV-01013022'
  },
  697285051478: {
    desc: 'SL CTRL 1" SB US RETAIL',
    plan: 'Enhanced Monthly',
    channel: 'In Store Retail',
    partNumber: 'UFCV-01012002'
  },
  697285057098: {
    desc: 'SL CTRL 1-1/4" SB US RETAIL',
    plan: 'Enhanced Monthly',
    channel: 'In Store Retail',
    partNumber: 'UFCV-01012003'
  }
}
