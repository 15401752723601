import { h } from 'preact'

const Loading = ({ cover }) => (
  <div class='loading'>
    <svg>
      <circle cx='7' cy='15' r='4' />
      <circle cx='30' cy='15' r='4' />
      <circle cx='53' cy='15' r='4' />
    </svg>
  </div>
)

export default Loading