import { h, render } from 'preact'
import { version } from '../package.json'
import App from './components/App.jsx'

render(<App />, document.body)

console.info('@streamlabs', {
  'build-env': process.env.STREAMLABS_FACTORY_API_ENV,
  version
})
